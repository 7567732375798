import {
  addCart,
  getCart,
  delCart,
  updateCartNum,
  updateCartUnChecked,
  updateCartChecked
} from '@/apis/cart'
import { Toast } from 'vant'

interface State {
  cartProducts: any[]
  allChecked: boolean
  alldelChecked: boolean
  allProducts: any[]
  addCartSuccess?: boolean
  cartsInfo?: any
  addressInfo?: Object
  isShopChecked?: boolean
  isEditState?: boolean
}

let CART_PRODUCTS: string | null | undefined
if (typeof window !== 'undefined') {
  // 判断当前环境是否是浏览器环境
  CART_PRODUCTS = localStorage.getItem('cart-products')
}
let skuLists: any[] = []

const state: State = {
  cartProducts: typeof CART_PRODUCTS === 'string' ? JSON.parse(CART_PRODUCTS) : [],
  allProducts: [],
  allChecked: false,
  alldelChecked: false,
  addCartSuccess: false,
  cartsInfo: {
    amount: '0'
  },
  addressInfo: {},
  isEditState: false,
  isShopChecked: true // 店铺按钮是否可选
}

const getters = {
  // 总数量
  totalCount(state: any) {
    return state.allProducts.reduce((total: any, value: { num: number }) => total + value.num, 0)
  },
  // 总价
  totalPrice(state: any) {
    return state.cartsInfo.amount
  },
  // 选中商品数量
  checkedCount(state: any) {
    console.log('state', state)
    return 0
  }
}
const mutations = {
  ADD_CART_SUCCESS(state: { addCartSuccess: any }, payload: any) {
    state.addCartSuccess = payload
  },
  GET_CART_PRODUCTS(
    state: {
      cartProducts: any
      allProducts: any
      cartsInfo: any
      allChecked: boolean
      isEditState: boolean
    },
    payload: any
  ) {
    state.cartsInfo = payload
    state.cartProducts = [].concat(payload.list)

    if (!state.isEditState) {
      // 非编辑状态
      state.cartProducts.forEach((item: any) => {
        state.allProducts = [].concat(state.allProducts, item.cartResList)
        item.selectState = item.cartResList
          .filter(
            (val: { ifHasStock: boolean; saleState: boolean }) => val.saleState && val.ifHasStock
          )
          .every((val: { selectState: any }) => val.selectState)
      })
      state.allChecked = state.allProducts
        .filter((val: { isEdit: boolean }) => !val.isEdit)
        .every((val: { selectState: any }) => val.selectState)
    } else {
      // 编辑状态
      state.cartProducts.forEach((item: any) => {
        state.allProducts = [].concat(state.allProducts, item.cartResList)
        item.selectState = item.cartResList.every((val: { selectState: any }) => val.selectState)
      })

      state.allChecked = state.allProducts.every((val: { selectState: any }) => val.selectState)
    }
  },
  CHANGE_ADDRESS_INFO(state: any, payload: any) {
    state.addressInfo = payload
  },
  CHANGE_EDIT_STATE(state: any, payload: boolean) {
    state.isEditState = payload
    state.allChecked = state.allProducts.every((val: { selectState: any }) => val.selectState)
  }
}

const actions = {
  // 加入购物车
  async addCart({ commit }: any, body: any) {
    try {
      const { code }: any = await addCart(body)
      if (code === '000000') commit('ADD_CART_SUCCESS', true)
    } catch (error) {
      console.error('error ----> ', error)
    }
  },
  // 获取购物车商品
  async getCart({ dispatch, state }: any, voucherId: string) {
    try {
      const { data, code }: any = await getCart(state.addressInfo?.id, voucherId)
      if (code === '000000') {
        dispatch('updateCart', data)
      }
    } catch (error) {
      console.error('error ----> ', error)
    }
  },
  // 删除购物车商品
  async delCart({ commit, state }: any, voucherId: string) {
    try {
      if (skuLists.length === 0) {
        Toast('请勾选您要删除的商品')
        return
      }
      const res: any = await delCart({
        skuIdList: skuLists,
        addressId: state.addressInfo.id,
        voucherTemplateId: voucherId
      })
      if (res.code === '000000') {
        skuLists = []
        await commit('GET_CART_PRODUCTS', res.data)
        Toast.success('删除成功')
      } else {
        Toast.fail(res.msg)
      }
    } catch (error) {
      console.error('error ----> ', error)
    }
  },
  // 更新购物车商品数量
  async updateCartNum({ dispatch, state, commit }: any, body: any) {
    try {
      const { data, code }: any = await updateCartNum(body)
      if (code === '000000') {
        if (!state.isEditState) {
          dispatch('updateCart', data)
        } else {
          const newData = state.cartProducts.map((item: { cartResList: any[] }) => {
            item.cartResList.map((val: any) => {
              if (val.skuId === body.skuId) val.num = val.num + body.updateNum
            })

            return item
          })
          state.allProducts = []
          commit('GET_CART_PRODUCTS', { list: newData, amount: state.cartsInfo.amount })
        }
      }
    } catch (error) {
      console.error('error----->', error)
    }
  },
  async updateChooseGood({ dispatch, state }: any, body: any) {
    let res: any = null
    if (body.selectState) {
      // 选中状态
      res = await updateCartUnChecked(body.req)
    } else {
      // 未选中状态
      res = await updateCartChecked(body.req)
    }
    if (res.code === '000000') {
      if (!state.isEditState) {
        dispatch('getCart', body.req.voucherTemplateId)
      } else {
        if (!body.isShop) {
          // 单商品编辑
          dispatch('updateDelSkus', body)
        } else {
          dispatch('updateDelShopSkus', { ...body, selectState: !body.selectState })
        }
      }
    }
  },
  changeAddr({ commit }: any, item: any) {
    commit('CHANGE_ADDRESS_INFO', item)
  },
  // 全选
  async updateAllProductChecked({ dispatch, state, commit }: any, voucherId: string) {
    if (!state.isEditState) {
      state.allProducts
        .filter((item: { isEdit: boolean }) => !item.isEdit)
        .map((item: any) => {
          if (!skuLists.includes(item.skuId)) skuLists.push(item.skuId)
        })
      const body = {
        req: {
          addressId: state.addressInfo.id,
          skuIdList: skuLists,
          voucherTemplateId: voucherId
        },
        selectState: state.allChecked
      }
      await dispatch('updateChooseGood', body)
      if (state.allChecked) {
        skuLists = []
      }
    } else {
      const newData = state.cartProducts.map(
        (item: { selectState: boolean; cartResList: any[] }) => {
          item.cartResList.map((val: any) => {
            val.selectState = !state.allChecked
          })
          item.selectState = !state.allChecked
          return item
        }
      )
      if (!state.allChecked) {
        state.allProducts.map((item: any) => {
          if (!skuLists.includes(item.skuId) && item.selectState) skuLists.push(item.skuId)
        })
      } else {
        skuLists = []
      }

      state.allProducts = []
      commit('GET_CART_PRODUCTS', { list: newData, amount: state.cartsInfo.amount })
    }
  },
  // 进入/退出管理
  async updateDelChecked({ dispatch, commit }: any, payload: any) {
    const data = payload.data
    commit('CHANGE_EDIT_STATE', data)
    if (data) {
      const newData = state.cartProducts.map(
        (item: { isEditShop: boolean; cartResList: any[]; selectState: boolean }) => {
          item.isEditShop = false
          item.cartResList.map((val: any) => {
            val.isEdit = false
          })
          return item
        }
      )
      state.allProducts = []
      commit('GET_CART_PRODUCTS', { list: newData, amount: state.cartsInfo.amount })
    } else {
      dispatch('getCart', payload.voucherId)
    }
  },
  async updateDelShopSkus({ commit, state }: any, data: any) {
    if (data.selectState) {
      data.cartResList.forEach((good: { selectState: boolean; skuId: string }) => {
        good.selectState = data.selectState
        if (!skuLists.includes(data.skuId)) skuLists.push(good.skuId)
      })
    } else {
      data.cartResList.forEach((good: { selectState: boolean; skuId: string }) => {
        good.selectState = data.selectState
        const index = skuLists.findIndex((val: any) => val === good.skuId)
        skuLists.splice(index, 1)
      })
    }
    // data.selectState = data.cartResList.some((prod: { selectState: boolean }) => !prod.selectState)
    const newData = state.cartProducts.map(
      (item: { selectState: boolean; cartResList: any[]; merchantId: string }) => {
        if (item.merchantId === data.merchantId) {
          item.selectState = data.selectState
          item.cartResList.map((val: any) => {
            val.selectState = data.selectState
          })
        }

        item.selectState = item.cartResList.every((prod: { selectState: any }) => prod.selectState)
        return item
      }
    )
    state.allProducts = []
    commit('GET_CART_PRODUCTS', { list: newData, amount: state.cartsInfo.amount })
  },
  async updateDelSkus({ commit, state }: any, body: any) {
    const shopProducts = state.cartProducts.find(
      (shop: { merchantId: any }) => shop.merchantId === body.merchantId
    )
    shopProducts.selectState = shopProducts.cartResList.every(
      (prod: { selectState: any }) => prod.selectState
    )
    if (!body.selectState && !skuLists.includes(body.skuId)) {
      skuLists.push(body.skuId)
    } else {
      const index = skuLists.findIndex((val: any) => val === body.skuId)
      skuLists.splice(index, 1)
    }
    const newData = state.cartProducts.map((item: { selectState: boolean; cartResList: any[] }) => {
      item.cartResList.map((val: any) => {
        if (val.skuId === body.skuId) val.selectState = !body.selectState
      })
      item.selectState = item.cartResList.every((prod: { selectState: any }) => prod.selectState)
      return item
    })
    state.allProducts = []
    commit('GET_CART_PRODUCTS', { list: newData, amount: state.cartsInfo.amount })
  },
  // 更新购物车数据
  async updateCart({ commit }: any, data: any) {
    const newData = data.list.map(
      (item: {
        saleShopState: boolean
        isEdit: boolean
        ifShopHasStock: boolean
        isEditShop: boolean
        cartResList: any[]
      }) => {
        item.isEditShop = item.cartResList.every((val: any) => !val.saleState || !val.ifHasStock)
        item.cartResList.map((val: any) => {
          val.isEdit = !val.saleState || !val.ifHasStock
          if (val.selectState && !skuLists.includes(val.skuId)) {
            skuLists.push(val.skuId)
          }
        })
        return item
      }
    )
    state.allProducts = []
    commit('GET_CART_PRODUCTS', { list: newData, amount: data.amount })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
