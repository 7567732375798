/*
 * @Author: your name
 * @Date: 2021-11-11 20:27:42
 * @LastEditTime: 2023-02-17 14:03:38
 * @LastEditors: tengwei
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yufu_h5_ssr/src/apis/cart.ts
 */
// 购物车相关

import axios from '@/utils/request'

/**
 * 添加商品到购物车
 * @param data
 * @returns
 */
export const addCart = (data: any) => {
  return axios({
    method: 'post',
    url: '/order-api/cart/add',
    data
  })
}

/**
 * 获取购物车
 * @returns
 */
export const getCart = (addressId = '', voucherTemplateId: string) => {
  return axios({
    method: 'get',
    url: `/order-api/cart/get/v2?addressId=${addressId}&voucherTemplateId=${voucherTemplateId}`
  })
}

/**
 * 删除购物车商品
 * @param data
 * @returns
 */
export const delCart = (data: any) => {
  return axios({
    method: 'post',
    url: '/order-api/cart/delete/v2',
    data
  })
}

/**
 * 更新购物车商品数量
 * @param data
 * @returns
 */
export const updateCartNum = (data: any) => {
  return axios({
    method: 'post',
    url: '/order-api/cart/update/v2',
    data
  })
}

/**
 * 更新详情页中购物车数量
 * @returns
 */
export const getShopCartCount = (id: any) => {
  return axios({
    method: 'post',
    url: `/order-api/cart/count?voucherTemplateId=${id}`
  })
}

/**
 * 购物车选中商品
 * @param data
 * @returns
 */
export const updateCartChecked = (data: any) => {
  return axios({
    method: 'post',
    url: '/order-api/cart/checked',
    data
  })
}

/**
 * 购物车取消选中商品
 * @param data
 * @returns
 */
export const updateCartUnChecked = (data: any) => {
  return axios({
    method: 'post',
    url: '/order-api/cart/un-checked',
    data
  })
}
