/**
 * 关闭当前页面
 * @param {*} args
 */
export const closeCurrentPage = function (args) {
  this.call('closeCurrentPage', args)
}

/**
 * 后退功能
 * @param {*} delta 后退几步
 * @param {*} cb 回调函数
 */
export const navigateGoBack = function (delta = 1) {
  this.call('navigateGoBack', { delta })
}

/**
 * 打开照相机
 * @param {*} args
 * @param {*} cb
 */
export const openCarema = function (args = {}, cb) {
  this.call('openCarema', args, cb)
}
/**
 * 打开相册
 * @param {*} args type: 1打开相机,2打开相册
 * @param {*} cb
 */
export const openPhotos = function (args = { type: 1 }, cb) {
  this.call('openPhotos', args, cb)
}
