/*
 * @Author: your name
 * @Date: 2021-11-08 20:39:08
 * @LastEditTime: 2023-03-09 15:54:25
 * @LastEditors: tengwei
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yufu_h5_ssr/src/apis/search.ts
 */
// 搜索相关

import axios from '@/utils/request'

/**
 *
 * @param data 搜索参数
 * @returns
 */
export const searchListAndShop = (data: object) => {
  return axios({
    method: 'post',
    url: '/goods-api/goods/spu/search-new',
    data
  })
}

/**
 * 搜索热词接口
 * @returns
 */
export const searchHot = () => {
  return axios({
    method: 'post',
    url: '/goods-api/goods/hot-word/list/v2'
  })
}

/**
 * 搜索默认词
 * @returns
 */
export const searchDefault = () => {
  return axios({
    method: 'post',
    url: '/goods-api/goods/default-word/query-valid'
  })
}

/**
 * 包含排序的搜索接口
 * @param data
 * @returns
 */
export const searchListV3 = (data: object) => {
  return axios({
    method: 'post',
    url: '/goods-api/goods/spu/search/v3',
    data
  })
}

/**
 * 根据条件查询商品spu的筛选条件
 * @returns
 */
export const fetchScreenCodition = (data: object) => {
  return axios({
    method: 'post',
    url: '/goods-api/goods/spu/search/screen',
    data
  })
}

/**
 * 根据条件联想查询
 * @returns
 */
export const fetchSuggestWords = (keyword: string) => {
  return axios({
    method: 'post',
    url: '/goods-api/suggest/words',
    data: {
      keyword
    }
  })
}
