/*
 * @Author: tengwei
 * @Date: 2022-01-19 14:47:31
 * @LastEditors: tengwei
 * @LastEditTime: 2023-05-06 14:23:33
 * @FilePath: /yufu_h5_ssr/src/apis/home.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 首页相关接口
import axios from '@/utils/request'

interface recommendReqType {
  limit: number
  orderBy: string
  page: number
  req: string | number | null | object
  count: boolean
  homePageId?: string | number
}

/**
 * 获取首页数据
 * @returns
 */
export const getHomeInfo = () => {
  return axios({
    method: 'post',
    url: '/goods-api/recommend/info'
  })
}

/**
 * 推荐接口
 * @param param0
 * @returns
 */
export const getHomeRecommendList = ({ limit, orderBy, page, req }: recommendReqType) => {
  return axios({
    method: 'post',
    url: '/goods-api/recommend/list-spu-by-topic-id',
    data: {
      limit,
      orderBy,
      page,
      req
    }
  })
}

/**
 * 获取首页顶部Topic
 * @returns
 */
export const getHomeTopics = () => {
  return axios({
    method: 'post',
    url: '/goods-api/goods/show-category/all'
  })
}

/**
 * 商品展示分类列表分页接口
 * @param param0
 * @returns
 *
 */
export const getPageByCondition = ({
  limit,
  orderBy,
  page,
  req,
  count = false
}: recommendReqType) => {
  return axios({
    method: 'POST',
    url: '/goods-api/goods/spu/page-by-condition',
    data: {
      limit,
      orderBy,
      page,
      req,
      count
    }
  })
}

/**
 *
 * @param data
 * @returns
 */
export const getPageCondition = ({
  limit,
  orderBy,
  page,
  req,
  count = false
}: recommendReqType) => {
  return axios({
    method: 'post',
    url: '/goods-api/recommend/page',
    data: {
      limit,
      orderBy,
      page,
      req,
      count
    }
  })
}

/**
 * 获取推荐位的信息
 * @param topicId 1017-支付页banner，1019-个人页banner  1020-个人积分页
 */
export const fetchGetRecBanner = (topicId: any) => {
  return axios({
    method: 'GET',
    url: '/goods-api/recommend/query-content-by-topic-id',
    data: {
      topicId
    }
  })
}

/**
 * 获取 裕福利 首页数据
 * @returns
 */
export const fetchHomeInfo = (goodsChannelId = '') => {
  return axios({
    method: 'GET',
    // url: '../../public/home.json'
    url: `/goods-api/homepage/index/v2?id=&goodsChannelId=${goodsChannelId}`
  })
}
export const fetchHomeInfoJson = (id = '') => {
  return axios({
    method: 'GET',
    url: `../../json/voucherId_${id}.json`
  })
}

/**
 * 获取 裕福利 首页猜你喜欢数据
 * @param param0
 * @returns
 */
export const fetchRemmendList = ({ limit, page, req, homePageId = '' }: recommendReqType) => {
  return axios({
    method: 'POST',
    url: `/goods-api/homepage/load_section?homePageId=${homePageId}`,
    data: {
      limit,
      page,
      req
    }
  })
}
/**
 * 获取 裕福利 专题猜你喜欢数据
 * @param param0
 * @returns
 */
export const fetchTopicRemmendList = ({ limit, page, req }: recommendReqType) => {
  return axios({
    method: 'POST',
    url: '/goods-api/recommend/page/v2',
    data: {
      limit,
      page,
      req
    }
  })
}
/**
 * 获取 裕福利 首页猜你喜欢数据草稿箱
 * @param param0
 * @returns
 */
export const fetchRemmendListDraft = ({ limit, page, req }: recommendReqType) => {
  return axios({
    method: 'POST',
    url: '/goods-api/homepage/load_section/draft',
    data: {
      limit,
      page,
      req
    }
  })
}

/**
 * 获取免密登录 Code 值
 * @returns
 */
export const fetchAuthCode = () => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/get-yufumall-code'
  })
}

/**
 * 专题页相关店铺推荐列表
 * @param param0
 * @param param1 req {"merId":111,"elementId":111}
 * @returns
 */
export const getPageByMerchat = ({ limit, page, req }: recommendReqType) => {
  return axios({
    method: 'POST',
    url: '/goods-api/merchant/homepage/load_section',
    data: {
      limit,
      page,
      req
    }
  })
}
