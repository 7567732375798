import { createApp } from './main'
// import { sync } from 'vuex-router-sync'
import './utils/rem.ts'
import Vconsole from 'vconsole'

const { app, router, store } = createApp()
if (process.env.NODE_ENV !== 'production') {
  let vConsole = new Vconsole()
  app.use(vConsole)
}

// 将 router 注入到 store 中
// sync(store, router, { moduleName: 'RouteModule' })

app.use(router).use(store)

router.beforeResolve((to, from, next) => {
  const matched = router.resolve(to).matched
  if (matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.user) {
      next({
        name: 'login',
        query: {
          redirect: to.fullPath // 把登录成功需要返回的页面告诉登录页面
        }
      })
    } else {
      next() // 允许通过
    }
  } else {
    next()
  }

  if (from && !from.name) {
    return next()
  } else {
    window.document.title =
      to.meta.title || (localStorage.getItem('channelSource') === '2' ? '福卡商城' : '裕福利')
  }

  const matchedComponents = []
  // eslint-disable-next-line array-callback-return
  matched.map((route) => {
    matchedComponents.push(...Object.values(route.components))
  })
  // eslint-disable-next-line array-callback-return
  const asyncDataFuncs = matchedComponents.map((component) => {
    const asyncData = component.asyncData || null
    if (asyncData) {
      const config = {
        store,
        route: to
      }

      return asyncData(config)
    }
  })

  try {
    Promise.all(asyncDataFuncs).then(() => {
      next()
    })
  } catch (error) {
    next(error)
  }
})

// 路由跳转之后回到顶部
router.afterEach(() => {
  window.scrollTo(0, 0)
})

if (window.__INIT_STATE__) {
  store.replaceState(window.__INIT_STATE__)
}

router.isReady().then(() => app.mount('#app'))
