interface State {
  couponTitle: String
}

const state: State = {
  couponTitle: ''
}

const getters = {}

const mutations = {
  SETCOUPONTITLE(state: { couponTitle: string }, payload: string) {
    state.couponTitle = payload
  }
}

const actions = {
  async getCouponInfo({ commit }: any, info: any) {
    console.log(info, 222)
    try {
      commit('SETCOUPONTITLE', info.voucherTypeName)
    } catch (error) {
      console.error('error ----> ', error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
